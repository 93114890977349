<template>
  <lab-task><lab-buffers-b /></lab-task>
</template>

<script>
import LabBuffersB from '../simulations/LabBuffersB';
import LabTask from './labs/LabTask';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'BuffersBSIM',
  components: {LabBuffersB, LabTask},
  mixins: [DynamicQuestionMixin()],
});
</script>
